import React from 'react';
import { Box, Text, Heading, Center, Link, Button } from '@chakra-ui/react';
import { FaInstagram } from 'react-icons/fa';
import Image from '@/components/Common/LazyImage';
import WithAnimation from '@/components/Common/WithAnimation';
import useLang from '@hooks/useLang';

import { IG_FILTER_URL } from '@/constants';
import { BG_INSTAGRAM_FILTER } from '@/constants/assets';
import txt from './locales';
import { BUTTON_GRADIENT, BUTTON_PROPS } from '@/constants/colors';

function InstagramFilter() {
  const lang = useLang();

  return (
    <Box bgColor="bgPrimary" width="100%">
      <Box textAlign="center" color="mainColorText" width="100%" position="relative">
        <Box padding="42px 32px">
          <WithAnimation>
            <Heading
              fontFamily="heading"
              textAlign="center"
              size="2xl"
              fontWeight="normal"
              marginBottom="16px"
              color="mainColorText"
            >
              Instagram <br />
              Wedding Filter
            </Heading>
            <Text fontSize="md" color="mainColorText" padding="0 12px 0 12px">
              <span dangerouslySetInnerHTML={{ __html: txt.subTitle[lang] }} />
            </Text>
          </WithAnimation>
        </Box>
        {/* Section Photo */}
        <WithAnimation>
          <Center>
            <Image src={BG_INSTAGRAM_FILTER} maxW="80%" borderRadius="16px" boxShadow="md" />
          </Center>
        </WithAnimation>
        {/* Thanks Section */}
        <Box padding="32px 32px">
          <WithAnimation>
            <Center>
              <Link
                href={IG_FILTER_URL}
                //fontFamily="body"
                color="mainColorText"
                target="_blank"
                style={{ textDecoration: 'none' }}
              >
                <Button
                  leftIcon={<FaInstagram />}
                  size="sm"
                  fontWeight="light"
                  fontSize="14px"
                  textTransform="uppercase"
                  colorScheme="blackAlpha"
                  bgColor="bgSecondary"
                  color="mainColorText"
                  {...BUTTON_PROPS}
                  background={BUTTON_GRADIENT}
                >
                  {txt.textButton[lang]}
                </Button>
              </Link>
            </Center>
          </WithAnimation>
        </Box>
      </Box>
    </Box>
  );
}

export default React.memo(InstagramFilter);
